import {
  Typography,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@mui/material";
import React, { useMemo, useRef, RefObject, useEffect } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import LinkIcon from "@mui/icons-material/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useGlobal } from "@/lib/hooks/GlobalHook";
import { isAndroid, isBrowser, isMobile } from "react-device-detect";
import {  useIsClient } from "usehooks-ts";
import ReactGA from "react-ga4";
import { CustomDropdown } from "./CustomDropdown";
import { ReactComponent as ShareIcon } from "@/assets/icon_share.svg";
import { useOutsideAlerter } from "@/lib/hooks/useOutsideAlerter";
import {useDarkMode} from "@/lib/hooks/useDarkModeHook"
import clsx from "clsx";
import {useReactGA} from "@/lib/hooks/useReactGAHook"

const popupCenter = ({
  url,
  title,
  w,
  h,
}: {
  url: string;
  title: string;
  w: number;
  h: number;
}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window?.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window) newWindow?.focus();
};

export const SharePanel = (props: {
  model: { title: string; description: string; prefix?: string };
  url: string;
  searchParams?: Record<string, string>;
}) => {
  const { model, url, searchParams } = props;
  const { global } = useGlobal();
  const isClient = useIsClient();
  const {eventTracker}=useReactGA()
  const {isDarkMode}=useDarkMode()
  const fullUrl = useMemo(() => {
    const u = new URL(`${window?.location?.origin}${url}`);
    if (searchParams) {
      Object.entries(searchParams).forEach((it) =>
        u.searchParams.set(it[0], it[1])
      );
    }
    return u.toString();
  }, [window?.location?.origin, searchParams, url]);
  const markShareEvent = (platform: string) => {
    eventTracker({
      action: "share_to",
      category: "Share",
      title: model.title,
      url: fullUrl,
      platform: platform,
    } as any);
  };
  return (
    <div className={clsx("rounded-t-md   pt-1 px-2 flex [&_svg:hover]:text-primary-500  [&_svg:hover]:transition-colors",
      isDarkMode?"bg-dark-content-300 text-dark-content-100 ":"bg-gray-500 text-white" 
    )}>
      {isClient && isMobile && navigator && navigator["share"] && isAndroid ? (
        <button
          aria-label="分享"
          onClick={(event) => {
            navigator.share({
              // url: url,
              title: model.title,
              text: `${model?.prefix ?? ""}${model?.title}\r\n${fullUrl}\r\n${
                global?.shareFooter ?? ""
              }`,
            });
          }}
        >
          <ShareIcon className="w-[18px] h-[18px] " />
        </button>
      ) : (
        <>
          <FacebookShareButton
            url={`${fullUrl}`}
            title={model?.title}
            hashtag={"channelchk"}
            quote={(model?.prefix ?? "") + model?.description}
            onClick={() => markShareEvent("facebook")}
          >
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton
            url={`${fullUrl}`}
            hashtags={["channelchk"]}
            title={(model?.prefix ?? "") + model?.title}
            onClick={() => markShareEvent("twitter")}
          >
            <TwitterIcon />
          </TwitterShareButton>
          <button
            onClick={() => {
              popupCenter({
                url: `https://telegram.me/share/url?url=${encodeURIComponent(
                  fullUrl
                )}&text=${encodeURIComponent(
                  `-----${model?.prefix ?? ""}${model?.title}\r\n${
                    global?.shareFooter ?? ""
                  }`
                )}`,
                title: "",
                w: 800,
                h: 600,
              });
              markShareEvent("telegram");
            }}
          >
            <TelegramIcon />
          </button>
          <button
            onClick={() => {
              popupCenter({
                url: `https://${
                  isBrowser ? "web" : "api"
                }.whatsapp.com/send?text=${encodeURIComponent(
                  `${model?.prefix ?? ""}${model?.title}\r\n${fullUrl}\r\n${
                    global?.shareFooter ?? ""
                  }`
                )}`,
                title: "",
                w: 800,
                h: 600,
              });
              markShareEvent("whatsapp");
            }}
          >
            <WhatsAppIcon />
          </button>
        </>
      )}
      <button aria-label="複製連結">
        <CopyToClipboard
          text={`${model?.prefix ?? ""}${model?.title}\r\n${fullUrl}\r\n${
            global?.shareFooter ?? ""
          }`}
          onCopy={() => {
            toast.success("已複製連結");
            markShareEvent("url");
          }}
        >
          <LinkIcon />
        </CopyToClipboard>
      </button>
    </div>
  );
};

export const ShareButton = (
  props: {
    model: { title: string; description: string };
    url: string;
    searchParams?: Record<string, string>;
    btnWithLabel?:boolean

  } & IconButtonProps
) => {
  const isClient = useIsClient();
  const { model, url, searchParams, btnWithLabel=false,...rest } = props;
  const shareBtnRef = useRef<HTMLDivElement>(null);
  const [shareOpen, setShareOpen] = React.useState(false);
  const {eventTracker} =useReactGA()
  const fullUrl = useMemo(() => {
    const u = new URL(`${window?.location?.origin}${url}`);
    if (searchParams) {
      Object.entries(searchParams).forEach((it) =>
        u.searchParams.set(it[0], it[1])
      );
    }
    return u.toString();
  }, [window?.location?.origin, searchParams, url]);
  const { global } = useGlobal();
  const markShareEvent = (platform: string) => {
    eventTracker({
      action: "share_to",
      category: "Share",
      title: model.title,
      url: fullUrl,
      platform: platform,
    } as any);
  };
  const {isDarkMode} =useDarkMode()
  useOutsideAlerter(shareBtnRef, () => setShareOpen(false));

  const isAndroidIcon = useMemo(() => {
    return (
      (isClient && isMobile && navigator && navigator["share"] && isAndroid) ??
      false
    );
  }, [isClient]);

  const clickShareBtnHandle=()=>{
    !isAndroidIcon && setShareOpen(true);
    eventTracker({
      action: "init_share",
      category: "Share",
      title: model.title,
      url: fullUrl,
    } as any)

    isAndroidIcon &&
      navigator.share({
        title: model.title,
        text: `${model?.title}\r\n${fullUrl}\r\n${
          global?.shareFooter ?? ""
        }`,
      });
  }
  
  return (
    <div
      className={clsx(" relative  ",)}
      ref={shareBtnRef}
    >
      <div className={clsx("   ",)}>
        <CustomDropdown isOpen={shareOpen} isFullScreen={false}  >
          <div className={clsx("",isDarkMode?" ":"" )}>
            <div
              className={clsx("w-full text-center block text-base",)} >
              分享至
            </div>
            <div className="flex flex-row [&>*>button]:p-2 [&>*>button]:lg:p-3 ">
              <Tooltip title="分享至Facebook" >
                <FacebookShareButton
                  url={`${fullUrl}`}
                  hashtag={"channelchk"}
                  quote={model?.description}
                  onClick={() => markShareEvent("facebook")}
                >
                  <IconButton size="large">
                    <FacebookIcon className={clsx("",isDarkMode&&"text-dark-content-100")}/>
                  </IconButton>
                </FacebookShareButton>
              </Tooltip>
              <Tooltip title="分享至Twitter">
                <TwitterShareButton
                  url={`${fullUrl}`}
                  hashtags={["channelchk"]}
                  onClick={() => markShareEvent("twitter")}
                >
                  <IconButton size="large">
                    <TwitterIcon className={clsx("",isDarkMode&&"text-dark-content-100")}/>
                  </IconButton>
                </TwitterShareButton>
              </Tooltip>
              <Tooltip title="分享至Telegram">
                <button
                  onClick={() => {
                    popupCenter({
                      url: `https://telegram.me/share/url?url=${encodeURIComponent(
                        fullUrl
                      )}&text=${encodeURIComponent(
                        `-----${model?.title}\r\n${global?.shareFooter ?? ""}`
                      )}}`,
                      title: "",
                      w: 800,
                      h: 600,
                    });
                    markShareEvent("telegram");
                  }}
                >
                  <IconButton size="large">
                    <TelegramIcon className={clsx("",isDarkMode&&"text-dark-content-100")}/>
                  </IconButton>
                </button>
              </Tooltip>
              <Tooltip title="分享至Whatsapp">
                <button
                  onClick={() => {
                    popupCenter({
                      url: `https://${
                        isBrowser ? "web" : "api"
                      }.whatsapp.com/send?text=${encodeURIComponent(
                        `${model?.title}\r\n${fullUrl}\r\n${
                          global?.shareFooter ?? ""
                        }`
                      )}`,
                      title: "",
                      w: 800,
                      h: 600,
                    });
                    markShareEvent("whatsapp");
                  }}
                >
                  <IconButton size="large">
                    <WhatsAppIcon className={clsx("",isDarkMode&&"text-dark-content-100")} />
                  </IconButton>
                </button>
              </Tooltip>
              <Tooltip title="複製連結">
                <div>
                  <CopyToClipboard
                    text={`${model?.title}\r\n${fullUrl}\r\n${
                      global?.shareFooter ?? ""
                    }`}
                    onCopy={() => {
                      toast.success("已複製連結");
                      markShareEvent("url");
                    }}
                  >
                    <IconButton size="large">
                      <LinkIcon className={clsx("",isDarkMode&&"text-dark-content-100")} />
                    </IconButton>
                  </CopyToClipboard>
                </div>
              </Tooltip>
            </div>
          </div>
        </CustomDropdown>
      </div>

      <div className={clsx(" relative flex flex-row items-center text-main gap-4 dark:text-dark-content-100 ",)}
        onClick={clickShareBtnHandle}
      >
        <IconButton
          aria-label="分享"
          {...rest}
          size={isAndroidIcon ? "large" : "medium"}
        >
          <ShareIcon
            className={clsx(
              "  text-main dark:text-dark-content-100  ",
              isAndroidIcon ? "w-[18px] h-[18px]" : "w-full h-full"
            )}
          />
        </IconButton>
        {btnWithLabel&&<div className="text-base whitespace-nowrap" 
        >分享</div>}
      </div>
    
    </div>
  );
};
export default ShareButton;

